/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

(async () => {
  // Get the fingerprint and ensure it's a string
  const fingerprint = String(await getCurrentBrowserFingerPrint());

  // Utility function to get a specific cookie by name
  const getCookie = (name) => {
    const match = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`));
    return match ? match.split("=")[1] : null;
  };

  // Utility function to set a cookie
  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    document.cookie = `${name}=${value}; path=/; expires=${date.toUTCString()}`;
  };

  // Retrieve current visitorID from cookie and localStorage
  const currentVisitorID = getCookie("visitorID");
  const localStorageVisitorID = localStorage.getItem("visitorID");

  // Update visitorID only if it's missing or mismatched
  if (
    currentVisitorID !== fingerprint ||
    localStorageVisitorID !== fingerprint
  ) {
    console.log("Updating visitorID in cookie and localStorage.");
    setCookie("visitorID", fingerprint, 365); // Store cookie for 1 year
    localStorage.setItem("visitorID", fingerprint); // Update localStorage
  } else {
    console.log("visitorID is already up-to-date.");
  }
})();

const jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

const bootstrap = require("bootstrap");
window.bootstrap = bootstrap;
global.bootstrap = bootstrap;

import { WOW } from "wowjs";
window.WOW = WOW;
global.WOW = WOW;

const sal = require("sal.js");
window.sal = sal;
global.sal = sal;

const Cookies = require("js-cookie");
window.Cookies = Cookies;
global.Cookies = Cookies;

const feather = require("../theme/vendor/feather.min");
window.feather = feather;
global.feather = feather;

require("../theme/vendor/modernizr.min");
require("../theme/vendor/waypoint.min");
require("../theme/vendor/counterup.min");
require("../theme/vendor/masonry");
require("../theme/vendor/imageloaded");
require("../theme/vendor/magnify.min");
require("../theme/vendor/lightbox");
require("../theme/vendor/slick.min");
require("../theme/vendor/easypie");
require("../theme/vendor/text-type");
require("../theme/vendor/jquery.style.swicher");
require("../theme/vendor/bootstrap-select.min");
require("../theme/vendor/backto-top");
require("../theme/vendor/jquery-one-page-nav");
require("../theme/main");
